import { RouteRecordRaw } from "vue-router";
import { Route } from "../ts-type/routes";
//公共路由
export const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/not-page",
    component: () => import("@/views/notPage/not-page.vue"),
  },
];
//静态路由
export const asyncRoutes: Array<Route> = [
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
      show:true,
      icon: "worker",
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/home/homeindex",
        name: "homeindex",
        meta: {
          title: "工作台",
          icon: "",
          show:true,
        },
        component: () =>
          import("@/views/home/index.vue"),
      },
    ],
  },
];


const routes: Array<any> = [...staticRoutes, ...asyncRoutes];

export default routes;
