import router from "./router/index";
import store from '@/store'
import { getModule } from "vuex-module-decorators";
import LoginStore from "./store/modules/login";
import Breadcrumb from "./store/modules/breadcrumb";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
// 商品管理
const commodityManagement: any = {
  // 全部商品
  allGoods: () => import("@/views/commodityManagement/allGoods/index.vue"),
  //商品分类
  commodityType: () => import("@/views/commodityManagement/commodityType/index.vue"),
  //商品规格
  goodsspec: () => import("@/views/commodityManagement/goodsspec/index.vue"),
}
//系统管理
const rightsManagement: any = {
  //商品属性管理
  attributeManagement: () => import("@/views/rightsManagement/attributeManagement/index.vue"),
  //首页banner定制
  homePageBanner: () => import("@/views/rightsManagement/homePageBanner/index.vue"),
  //用户管理前台
  userManagement: () => import("@/views/rightsManagement/userManagement/index.vue"),
  //用户管理后台
  userManagementBackstage: () => import("@/views/rightsManagement/userManagementBackstage/index.vue"),
  //角色
  roleManagement: () => import("@/views/rightsManagement/roleManagement/index.vue"),
  //菜单
  menuManagement: () => import("@/views/rightsManagement/menuManagement/index.vue"),
  // 字典管理
  dictionaryManagement: () => import("@/views/rightsManagement/dictionaryManagement/index.vue"),
}
//订单管理
const orderManagement: any = {

}
//商城管理
const mallManagement: any = {
  // 发货地管理
  placeOfShipmentManagement: () => import("@/views/mallManagement/placeOfShipmentManagement/index.vue"),
  // 运费管理
  freightManagement: () => import("@/views/mallManagement/freightManagement/index.vue"),
}
// 前端路由表
const constantRouterComponents: any = {
  // 商品管理
  commodityManagement: () => import("@/layout/index.vue"),
  ...commodityManagement,
  //订单管理
  orderManagement: () => import("@/layout/index.vue"),
  ...orderManagement,
  //商城管理
  mallManagement: () => import("@/layout/index.vue"),
  ...mallManagement,
  //系统管理
  rightsManagement: () => import("@/layout/index.vue"),
  ...rightsManagement
};


/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (val: any) => {
  return val.map((item: any) => {
    const { menuName, routeName, isShow, component, path, icon, children } = item
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path,
      // 路由名称，建议唯一
      name: routeName,
      // 该路由对应页面的 组件  (动态加载)
      component: constantRouterComponents[routeName],
      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: menuName,
        icon: icon,
        show: isShow,
      },
      children: children
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children ? item.children : [])
    }
    return currentRouter
  })
}


type Token = string | null | undefined

/**
 * 路由前置钩子函数，可以做点什么
 */
router.beforeEach(async function (to, form, next) {
  const breadcrumb = getModule(Breadcrumb, store)
  breadcrumb.setMatched(to.matched)
  const token: Token = cookies.get('TOKEN_ACCOUNT')
  const { currentUser } = store.getters
  if (token) {
    if (!currentUser) {
      const loginStore = getModule(LoginStore, store)
      const userInfo: any = await loginStore.getCurrentUser()
      generator(userInfo.data.menus ? userInfo.data.menus : []).map((res: any) => {
        router.addRoute(res)
      })
      router.addRoute({
        path: "/:catchAll(.*)",
        redirect: "/not-page",
      })
      next({ ...to, replace: true })
    } else {
      next()
    }
  } else {
    if (to.path !== '/login') {
      next({ path: '/login' });
    } else {
      next()
    }
  }
});
