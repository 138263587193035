import JSEncrypt from 'jsencrypt'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAg49IRFZNuTA1pcBtlvi8HsuGpVVzVbupkjnNHI02Ox2esxLA7NUS+SeNBY14iV+ew7pSZ4HI0X1Syq/Tpl0jhMPGXpJEqrsVE7K3FbL6YW/ByKVbcJW3l77fmCymustygeqQzwk2dGA56dzNp0T6OS0LAK6vweMRKfhA8uRnb/jKgeLP2AtlejXqcW+i3ShTZ4wglFP8IK8u7IokJJuvDGfkwJ3WvR7HONX1Jkrvmc9mT8b9zWm7gv4SKKgXelCYmmz4//Eu3QJDeomnSmC6fVEbCuF4uRMmVXFIxJ0t65DE+Tfe71vZIzBv/ZLdKjMnqW+C/3CKrV34wkBRV2lOfQIDAQAB'

// 加密
export function encrypt(txt:any) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}

