import {
  Textarea,
  Alert,
  Avatar,
  BackTop,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Calendar,
  Card,
  Collapse,
  Comment,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  FormItem,
  Input,
  InputNumber,
  Layout,
  LayoutSider,
  LayoutContent,
  LayoutHeader,
  LayoutFooter,
  List,
  LocaleProvider,
  Menu,
  MenuItem,
  Mentions,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Transfer,
  Tree,
  TreeSelect,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Drawer,
  Skeleton,
  Result,
  Descriptions,
  PageHeader,
  Space,
  InputPassword,
  RangePicker,
  RadioGroup,
  CheckboxGroup,
  message,
  InputSearch,
  RadioButton,
} from "ant-design-vue";
const componentArrays = [
  Alert,
  Avatar,
  BackTop,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Calendar,
  Card,
  Collapse,
  Comment,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  FormItem,
  Input,
  InputPassword,
  InputNumber,
  Layout,
  LayoutSider,
  LayoutContent,
  LayoutHeader,
  LayoutFooter,
  List,
  LocaleProvider,
  Menu,
  MenuItem,
  Mentions,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Transfer,
  Tree,
  TreeSelect,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Drawer,
  Skeleton,
  Result,
  Descriptions,
  PageHeader,
  Space,
  Textarea,
  RangePicker,
  RadioGroup,
  CheckboxGroup,
  message,
  InputSearch,
  RadioButton
];
import "ant-design-vue/dist/antd.css";
import type { App } from "vue";
import * as Icons from "@ant-design/icons-vue";
const icons: any = Icons;

export default function (app: App) {
  for (const component of componentArrays) {
    app.component(component.name, component);
  }
  for (const i in icons) {
      app.component(i, icons[i]);
  }
}
