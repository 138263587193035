import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import useApp from "./ant-design-vue";
import VueCookies from "vue3-cookies";
import "./style/index.less";
import "./berforEach";
import "./axios-config";
import { message, Modal } from "ant-design-vue";
const app = createApp(App);
app.config.globalProperties.$message = message;
app.config.globalProperties.$confirm = Modal.confirm;
app.use(useApp).use(store).use(router).use(VueCookies).mount("#app");
