import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import { RouteRecordRaw } from "vue-router";

@Module({namespaced: true, name: "breadcrumb", store})
export default class Breadcrumb  extends VuexModule {
    matched: RouteRecordRaw[] = []

    @Mutation
    setMatched(matched: RouteRecordRaw[]) {
        this.matched = matched
    }
}