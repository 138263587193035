import axios from "../axios-config";
import { LoginForm } from "@/ts-type/login";
import { AxiosRequestConfig } from "axios";
import { encrypt } from '@/utils/rsaEncrypt'
/**
 * 登录接口
 * @param {*} loginForm
 */
export function login(loginForm:any) {
  const data = {
    username: loginForm.username,
    password: encrypt(loginForm.password)
  }
  return axios.post("/auth/login", {...data});
}

/**
 * 登录接口（个人信息）
 * @param {*}
 */
 export function getCurrentUser() {
  return axios.get("/auth/current");
}


