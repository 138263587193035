
import { defineComponent, onBeforeUnmount } from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default defineComponent({
  setup(props) {
    return {
      locale: zhCN
    }
  }
})
